import appUtils from '../utils/appUtils'
import axios from './axios'

const localEndpoint = 'http://localhost:1234/api/'

export default class BaseRequest {
  _errorHandler (reject, err, showMessage = true) {
    // Hide loading.
    try {
      // console.log(err.response)
      window.$progress.finish()
      // console.log(err.response)
      if (err.response) {
        if (err.response.status === 401 || err.response.status === 406) {
          appUtils.onClearUser()
          window.$router.push({ path: '/login' }).catch(() => { })
          reject(err)
          return
        } else if (err.response.data.status === 414 && showMessage) {
          let firstKey = Object.keys(err.response.data.message)[0]
          if (firstKey) {
            console.log(err.toJSON())
          }
        } else if (err.response.status === 422 && showMessage) {
          // let messages = Object.values(err.response.data.errors).map(
          //   (errs) => '<span class="text-danger"> - ' + errs[0] + '</span>'
          // )
          let messages = Object.values(err.response.data.errors).map(
            (errs) => errs[0]
          )

          window.$message({
            type: 'error',
            message: messages[0],
            showClose: true,
            duration: 5000
          })
          console.log(err.toJSON())
          // window.$toast.noti(false, messages.join("<br />"));
        } else if (
          (err.response.status === 500 || err.response.code === 500) &&
          showMessage
        ) {
          // console.log(err.response)
          reject(err.response)
          return
        } else if (err.response.status === 409) {
          const error = err.response.data['Error!']

          window.$message({
            type: 'error',
            message: 'Tên tổ chức đã tồn tại!',
            showClose: true,
            duration: 5000
          })
          console.log(error.toJSON())
        } else if (err.response.data.message && showMessage) {
          console.log(err.toJSON())
          //
          // window.$message({
          //   type: 'error',
          //   message: err.response.data.message,
          //   showClose: true,
          //   duration: 5000
          // })
          // window.$toast.noti(false, err.response.data.message)
        } else {
          console.log(err.toJSON())
        }
      }

      reject(err)
    } catch (error) {
      console.log(error.toJSON())
      reject(error)
    }
  }

  get (url, params, showMessage, configs = null) {
    return new Promise((resolve, reject) => {
      // Show loading.
      window.$progress.start()

      axios
        .get(url, { params, ...configs })
        .then((response) => {
          // Hide loading.
          window.$progress.finish()

          if (response.data?.code === 200) {
            resolve(response.data)
            return
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response)
          }
        })
        .catch((error) => {
          this._errorHandler(reject, error, showMessage)
        })
    })
  }
  getDev (url, params, showMessage, configs = null) {
    return new Promise((resolve, reject) => {
      // Show loading.
      window.$progress.start()

      axios
        .get(url, { params, ...configs, baseURL: localEndpoint })
        .then((response) => {
          // Hide loading.
          window.$progress.finish()

          if (response.data.code === 200) {
            resolve(response.data)
            return
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response)
          }

          // if(response.data.status === 406){
          //     window.location.href = "/login";
          //     return;
          // }
        })
        .catch((error) => {
          // reject(error)
          this._errorHandler(reject, error, showMessage)
        })
    })
  }

  post (url, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      let config = {}
      if (headers) {
        config.headers = headers
      }

      // Show loading.
      window.$progress.start()

      axios
        .post(url, data, { ...config })
        .then((response) => {
          // Hide loading.
          window.$progress.finish()

          if (response.data?.message && showMessage) {
            window.$toast.noti(true, response.data?.message)
          }
          if (response.data?.code === 200) {
            resolve(response.data)
            return
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response)
            return
          }
          if (response.status === 406) {
            // window.location.href = "/login";
          }
        })
        .catch((error) => {
          // reject(error)
          this._errorHandler(reject, error, showMessage)
        })
    })
  }
  postDev (url, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      let config = {}
      if (headers) {
        config.headers = headers
      }

      // Show loading.
      window.$progress.start()

      axios
        .post(url, data, {
          ...config,
          baseURL: localEndpoint
        })
        .then((response) => {
          // Hide loading.
          window.$progress.finish()

          if (response.data?.message && showMessage) {
            window.$toast.noti(true, response.data?.message)
          }
          if (response.data?.code === 200) {
            resolve(response.data)
            return
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response)
            return
          }
          if (response.status === 406) {
            // window.location.href = "/login";
          }
        })
        .catch((error) => {
          // reject(error)
          this._errorHandler(reject, error, showMessage)
        })
    })
  }

  delete (url, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      let config = {}
      if (headers) {
        config.headers = headers
      }

      // Show loading.
      window.$progress.start()

      axios
        .delete(url, data, { ...config })
        .then((response) => {
          // Hide loading.
          window.$progress.finish()

          if (response.data.message && showMessage) {
            window.$toast.noti(true, response.data.message)
          }
          if (response.data.code === 200) {
            resolve(response.data)
            return
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response)
            return
          }
          if (response.status === 406) {
            // window.location.href = "/login";
          }
        })
        .catch((error) => {
          // reject(error)
          this._errorHandler(reject, error, showMessage)
        })
    })
  }
  deleteDev (url, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      let config = {}
      if (headers) {
        config.headers = headers
      }

      // Show loading.
      window.$progress.start()

      axios
        .delete(url, data, { ...config, baseURL: localEndpoint })
        .then((response) => {
          // Hide loading.
          window.$progress.finish()

          if (response.data.message && showMessage) {
            window.$toast.noti(true, response.data.message)
          }
          if (response.data.code === 200) {
            resolve(response.data)
            return
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response)
            return
          }
          if (response.status === 406) {
            // window.location.href = "/login";
          }
        })
        .catch((error) => {
          // reject(error)
          this._errorHandler(reject, error, showMessage)
        })
    })
  }

  put (url, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      let config = {}
      if (headers) {
        config.headers = headers
      }

      // Show loading.
      window.$progress.start()

      axios
        .put(url, data, { ...config })
        .then((response) => {
          // Hide loading.
          window.$progress.finish()

          if (response.data.message && showMessage) {
            window.$toast.noti(true, response.data.message)
          }
          if (response.data.code === 200) {
            resolve(response.data)
            return
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response)
            return
          }
          if (response.status === 406) {
            // window.location.href = "/login";
          }
        })
        .catch((error) => {
          // reject(error)
          this._errorHandler(reject, error, showMessage)
        })
    })
  }
  putDev (url, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      let config = {}
      if (headers) {
        config.headers = headers
      }

      // Show loading.
      window.$progress.start()

      axios
        .put(url, data, { ...config, baseURL: localEndpoint })
        .then((response) => {
          // Hide loading.
          window.$progress.finish()

          if (response.data.message && showMessage) {
            window.$toast.noti(true, response.data.message)
          }
          if (response.data.code === 200) {
            resolve(response.data)
            return
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response)
            return
          }
          if (response.status === 406) {
            // window.location.href = "/login";
          }
        })
        .catch((error) => {
          // reject(error)
          this._errorHandler(reject, error, showMessage)
        })
    })
  }

  postByUrl (url, baseURL, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      const config = {}
      if (headers) {
        config.headers = headers
      }

      // Show loading.
      window.$progress.start()

      axios
        .post(url, data, { ...config, baseURL })
        .then((response) => {
          // Hide loading.
          window.$progress.finish()

          if (response.data.message && showMessage) {
            window.$toast.open({
              message: response.data.message,
              type: 'success'
            })
          }
          if (response.data.code === 200) {
            resolve(response.data)
            return
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response)
            return
          }
          if (response.status === 406) {
            // window.location.href = "/login";
          }
        })
        .catch((error) => {
          this._errorHandler(reject, error, showMessage)
        })
    })
  }
}
