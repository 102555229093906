import appUtils from '../utils/appUtils'
import axios from './axios'
import AdminRequest from './request/AdminRequest'
import AuthRequest from './request/AuthRequest'
import ClinicRequest from './request/ClinicRequest'
import CommonRequest from './request/CommonRequest'
import ConferenceRequest from './request/ConferenceRequest'
import DoctorRequest from './request/DoctorRequest'
import EHCSyncRequest from './request/EHCSyncRequest'
import VimesSyncRequest from './request/VimesSyncRequest'
import HIDRequest from './request/HIDRequest'
import QAndARequest from './request/QAndARequest'
import TmatsuokaRequest from './request/TmatsuokaRequest'

const requestMap = {
  AuthRequest,
  AdminRequest,
  CommonRequest,
  ClinicRequest,
  DoctorRequest,
  ConferenceRequest,
  QAndARequest,
  EHCSyncRequest,
  VimesSyncRequest,
  TmatsuokaRequest,
  HIDRequest
}
const instances = {}

export default class RequestFactory {
  getRequest (classname) {
    if (appUtils.getLocalToken()) {
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + appUtils.getLocalToken()
    }

    axios.defaults.headers.common['Content-Type'] = 'application/json'
    let RequestClass = requestMap[classname]
    if (!RequestClass) {
      throw new Error('Invalid request class name: ' + classname)
    }

    let requestInstance = instances[classname]
    if (!requestInstance) {
      requestInstance = new RequestClass()
      instances[classname] = requestInstance
    }
    return requestInstance
  }
}
