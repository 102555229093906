import appUtils from '@/utils/appUtils'
import axios from './axios'

export default class HIDBaseRequest {
  getUrlPrefix (url) {
    if (url.includes('https://') || url.includes('http://')) return url
    const endPoint = process.env.VUE_APP_HID_API.replace('/api/go', '')
    return `${endPoint}api/${url}`
  }

  getUrlPrefixDev (url) {
    const endpoint = 'http://localhost:40001'
    return `${endpoint}/api/${url}`
  }

  _errorHandler (reject, err, showMessage = true) {
    // Hide loading.
    try {
      // console.log(err.response)
      // window.$progress.finish()
      // console.log(err.response)
      if (err.response) {
        if (err.response.status === 401 || err.response.status === 406) {
          appUtils.onClearUser()
          window.$router.push({ path: '/login' }).catch(() => { })
          reject(err)
          return
        } else if (err.response.data.status === 414 && showMessage) {
          let firstKey = Object.keys(err.response.data.message)[0]
          if (firstKey) {
            console.log(err.response.data.message[firstKey][0])
          }
        } else if (err.response.status === 422 && showMessage) {
          // let messages = Object.values(err.response.data.errors).map(
          //   (errs) => '<span class="text-danger"> - ' + errs[0] + '</span>'
          // )
          let messages = Object.values(err.response.data.errors).map(
            (errs) => errs[0]
          )

          window.$message({
            type: 'error',
            message: messages[0],
            showClose: true,
            duration: 5000
          })
          console.log(err.response.data.errors)
          // window.$toast.noti(false, messages.join("<br />"));
        } else if (
          (err.response.status === 500 || err.response.code === 500) &&
          showMessage
        ) {
          // console.log(err.response)
          reject(err.response)
          return
        } else if (err.response.status === 409) {
          const error = err.response.data['Error!']

          window.$message({
            type: 'error',
            message: 'Tên tổ chức đã tồn tại!',
            showClose: true,
            duration: 5000
          })
          console.log(error)
        } else if (err.response.data.message && showMessage) {
          console.log(err.response)
          //
          // window.$message({
          //   type: 'error',
          //   message: err.response.data.message,
          //   showClose: true,
          //   duration: 5000
          // })
          // window.$toast.noti(false, err.response.data.message)
        } else {
          console.log(err.response)
        }
      }

      reject(err)
    } catch (error) {
      console.log(error)
    }
  }

  get (url, params, showMessage, configs = null) {
    return new Promise((resolve, reject) => {
      // Show loading.
      // window.$progress.start()

      axios
        .get(this.getUrlPrefix(url), { params, ...configs })
        .then((response) => {
          // Hide loading.
          // window.$progress.finish()

          if (response.data?.code === 200) {
            resolve(response.data)
            return
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response)
          }
        })
        .catch((error) => {
          this._errorHandler(reject, error, showMessage)
        })
    })
  }
  getDev (url, params, showMessage, configs = null) {
    return new Promise((resolve, reject) => {
      // Show loading.
      // window.$progress.start()

      axios
        .get(this.getUrlPrefixDev(url), { params, ...configs })
        .then((response) => {
          // Hide loading.
          // window.$progress.finish()

          if (response.data.code === 200) {
            resolve(response.data)
            return
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response)
          }

          // if(response.data.status === 406){
          //     window.location.href = "/login";
          //     return;
          // }
        })
        .catch((error) => {
          // reject(error)
          this._errorHandler(reject, error, showMessage)
        })
    })
  }

  post (url, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      let options = {}
      if (headers) {
        options.headers = headers
      }

      // Show loading.
      // window.$progress.start()

      axios
        .post(this.getUrlPrefix(url), data, options, showMessage)
        .then((response) => {
          // Hide loading.
          // window.$progress.finish()

          if (response.data?.message && showMessage) {
            window.$toast.noti(true, response.data?.message)
          }
          if (response.data?.code === 200) {
            resolve(response.data)
            return
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response)
            return
          }
          if (response.status === 406) {
            // window.location.href = "/login";
          }
        })
        .catch((error) => {
          // reject(error)
          this._errorHandler(reject, error, showMessage)
        })
    })
  }
  postDev (url, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      let options = {}
      if (headers) {
        options.headers = headers
      }

      // Show loading.
      // window.$progress.start()

      axios
        .post(this.getUrlPrefixDev(url), data, options, showMessage)
        .then((response) => {
          // Hide loading.
          // window.$progress.finish()

          if (response.data?.message && showMessage) {
            window.$toast.noti(true, response.data?.message)
          }
          if (response.data?.code === 200) {
            resolve(response.data)
            return
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response)
            return
          }
          if (response.status === 406) {
            // window.location.href = "/login";
          }
        })
        .catch((error) => {
          // reject(error)
          this._errorHandler(reject, error, showMessage)
        })
    })
  }

  delete (url, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      let options = {}
      if (headers) {
        options.headers = headers
      }

      // Show loading.
      // window.$progress.start()

      axios
        .delete(this.getUrlPrefix(url), data, options, showMessage)
        .then((response) => {
          // Hide loading.
          // window.$progress.finish()

          if (response.data.message && showMessage) {
            window.$toast.noti(true, response.data.message)
          }
          if (response.data.code === 200) {
            resolve(response.data)
            return
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response)
            return
          }
          if (response.status === 406) {
            // window.location.href = "/login";
          }
        })
        .catch((error) => {
          // reject(error)
          this._errorHandler(reject, error, showMessage)
        })
    })
  }
  deleteDev (url, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      let options = {}
      if (headers) {
        options.headers = headers
      }

      // Show loading.
      // window.$progress.start()

      axios
        .delete(this.getUrlPrefixDev(url), data, options, showMessage)
        .then((response) => {
          // Hide loading.
          // window.$progress.finish()

          if (response.data.message && showMessage) {
            window.$toast.noti(true, response.data.message)
          }
          if (response.data.code === 200) {
            resolve(response.data)
            return
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response)
            return
          }
          if (response.status === 406) {
            // window.location.href = "/login";
          }
        })
        .catch((error) => {
          // reject(error)
          this._errorHandler(reject, error, showMessage)
        })
    })
  }

  put (url, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      let options = {}
      if (headers) {
        options.headers = headers
      }

      // Show loading.
      // window.$progress.start()

      axios
        .put(this.getUrlPrefix(url), data, options, showMessage)
        .then((response) => {
          // Hide loading.
          // window.$progress.finish()

          if (response.data.message && showMessage) {
            window.$toast.noti(true, response.data.message)
          }
          if (response.data.code === 200) {
            resolve(response.data)
            return
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response)
            return
          }
          if (response.status === 406) {
            // window.location.href = "/login";
          }
        })
        .catch((error) => {
          // reject(error)
          this._errorHandler(reject, error, showMessage)
        })
    })
  }
  putDev (url, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      let options = {}
      if (headers) {
        options.headers = headers
      }

      // Show loading.
      // window.$progress.start()

      axios
        .put(this.getUrlPrefixDev(url), data, options, showMessage)
        .then((response) => {
          // Hide loading.
          // window.$progress.finish()

          if (response.data.message && showMessage) {
            window.$toast.noti(true, response.data.message)
          }
          if (response.data.code === 200) {
            resolve(response.data)
            return
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response)
            return
          }
          if (response.status === 406) {
            // window.location.href = "/login";
          }
        })
        .catch((error) => {
          // reject(error)
          this._errorHandler(reject, error, showMessage)
        })
    })
  }

  postByUrl (url, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      const options = {}
      if (headers) {
        options.headers = headers
      }

      // Show loading.
      // window.$progress.start()

      axios
        .post(url, data, options, showMessage)
        .then((response) => {
          // Hide loading.
          // window.$progress.finish()

          if (response.data.message && showMessage) {
            window.$toast.open({
              message: response.data.message,
              type: 'success'
            })
          }
          if (response.data.code === 200) {
            resolve(response.data)
            return
          }
          if (response.status === 200) {
            resolve(response)
            return
          }
          if (response.status === 406) {
            // window.location.href = "/login";
          }
        })
        .catch((error) => {
          this._errorHandler(reject, error, showMessage)
        })
    })
  }
}
